<template>
  <div>
    <template v-if="cart.length > 0 || pagado">    
      <div style="background-color: #efece8" v-if="cart.length > 0">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="8" md="6" class="py-6">
              <v-simple-table height="240">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">
                        <span class="bold">Producto</span>
                      </td>
                      <td class="text-left">
                        <span class="bold">Cantidad</span>
                      </td>
                      <td class="text-left">
                        <span class="bold">Precio</span>
                      </td>
                    </tr>
                    <tr v-for="(item, i) in cart" :key="i">
                      <td>
                        {{ item.presentacion.producto.categoria }}
                        {{ item.presentacion.producto.nombre }}
                        {{ item.presentacion.producto.clase.name }}
                        {{ item.presentacion.contenido }}
                      </td>
                      <td>{{ item.cantidad }}</td>
                      <td>${{ numberWithCommas(item.presentacion.precio) }}</td>
                    </tr>
                    <tr>
                      <td class="text-right" colspan="2">
                        <span class="bold">Subtotal</span>
                      </td>
                      <td>
                        <span>${{ numberWithCommas(total) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right" colspan="2">
                        <span class="bold">Envío</span>
                      </td>
                      <td>
                        <span>${{ numberWithCommas(envio) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right" colspan="2">
                        <span class="bold">Total</span>
                      </td>
                      <td>
                        <span>${{ numberWithCommas(precioFinal) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container id="stepper">
        <v-row>
          <v-col>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step
                  :complete="valid"
                  step="1"
                  @click="e1 = 1"
                  class="pointer"
                  color="#A06A4C"
                >
                  <span class="titles bold d-inline-block ml-3"
                    >Datos de envío</span
                  >
                </v-stepper-step>

                <v-stepper-step
                  :complete="pagado"
                  step="2"
                  v-if="valid"
                  @click="e1 = 2"
                  class="pointer"
                  color="#A06A4C"
                >
                  <span class="titles bold d-inline-block ml-3">Pago</span>
                </v-stepper-step>
                <v-stepper-step
                  :complete="pagado"
                  step="2"
                  v-else
                  color="#A06A4C"
                >
                  <span class="titles bold d-inline-block ml-3">Pago</span>
                </v-stepper-step>

                <v-stepper-step step="3" color="#A06A4C" :complete="pagado">
                  <span class="titles bold d-inline-block ml-3">Resumen</span>
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="form" v-model="valid">
                    <h2>1. Datos de contacto</h2>

                    <v-row class="my-0">
                      <v-col cols="6">
                        <v-text-field
                          v-model="nombre"
                          :rules="requerido"
                          label="Nombre"
                          required
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="apellido"
                          :rules="requerido"
                          label="Apellido"
                          required
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="my-0">
                      <v-col cols="6">
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="telefono"
                          :rules="requerido"
                          label="Teléfono"
                          required
                          type="number"
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <h2>2. Datos de entrega</h2>

                    <v-row class="my-0">
                      <v-col cols="6">
                        <v-text-field
                          v-model="calle"
                          :rules="requerido"
                          label="Calle"
                          required
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="numero"
                          :rules="requerido"
                          label="Número"
                          required
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="my-0">
                      <v-col cols="6">
                        <v-text-field
                          v-model="colonia"
                          :rules="requerido"
                          label="Colonia"
                          required
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="codigoPostal"
                          :rules="requerido"
                          label="Código Postal"
                          required
                          type="number"
                          color="#A06A4C"
                          :disabled="pagado"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row cols="my-0">
                      <v-col cols="6">
                        <template v-if="ciudadSeleccionada">
                          <v-text-field
                            v-model="ciudadEnvio"
                            :rules="requerido"
                            label="Ciudad / Municipio"
                            required
                            disabled
                            color="#A06A4C"
                          ></v-text-field>
                        </template>
                        <template v-else>
                          <v-text-field
                            v-model="ciudadEnvio"
                            :rules="requerido"
                            label="Ciudad / Municipio"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </template>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="estadoEnvio"
                          :rules="requerido"
                          label="Estado"
                          required
                          disabled
                          color="#A06A4C"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <h2>3. Facturación</h2>

                    <v-switch
                      v-model="factura"
                      :label="'¿Requieres factura?'"
                      color="#A06A4C"
                      :disabled="pagado"
                    ></v-switch>

                    <template v-if="factura">
                      <v-row class="my-0">
                        <v-col cols="6">
                          <v-text-field
                            v-model="razonSocial"
                            :rules="requerido"
                            label="Razón Social"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="rfc"
                            :rules="requerido"
                            label="RFC"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="my-0">
                        <v-col cols="6">
                          <v-text-field
                            v-model="calleFactura"
                            :rules="requerido"
                            label="Calle"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="numeroFactura"
                            :rules="requerido"
                            label="Número"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="my-0">
                        <v-col cols="6">
                          <v-text-field
                            v-model="coloniaFactura"
                            :rules="requerido"
                            label="Colonia"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="codigoPostalFactura"
                            :rules="requerido"
                            label="Código Postal"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="my-0">
                        <v-col cols="6">
                          <v-text-field
                            v-model="ciudadFactura"
                            :rules="requerido"
                            label="Ciuadad / Municipio"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="estadoFactura"
                            :rules="requerido"
                            label="Estado"
                            required
                            color="#A06A4C"
                            :disabled="pagado"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </template>

                    <div class="mt-6">
                      <v-btn
                        color="#ADC332"
                        class="mr-4"
                        height="45"
                        width="600"
                        max-width="100%"
                        @click="validarDatosEnvio"
                      >
                        Continuar
                      </v-btn>
                    </div>
                  </v-form>

                  <!-- <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>

                  <v-btn text> Cancel </v-btn> -->
                </v-stepper-content>

                <v-stepper-content step="2">
                  <div class="mx-auto" style="max-width: 600px">                    
                    <v-row v-if="!pagado" justify="center">
                      <v-col cols="4" sm="auto">
                        <v-img
                          src="../assets/img/conekta-tarjetas.png"
                          max-width="142"
                          class="pointer"
                          v-bind:class="{
                            'non-selected':
                              selected == 'paypal' || selected == 'oxxo',
                          }"
                          @click="selected = 'tarjetas'"
                        >
                        </v-img>
                      </v-col>
                      <v-col cols="4" sm="auto">
                        <v-img
                          src="../assets/img/paypal.png"
                          max-width="142"
                          class="pointer"
                          v-bind:class="{
                            'non-selected':
                              selected == 'tarjetas' || selected == 'oxxo',
                          }"
                          @click="selected = 'paypal'"
                        >
                        </v-img>
                      </v-col>
                      <!-- <v-col cols="4" sm="auto">
                        <v-img src="../assets/img/oxxo.png" max-width="142" class="pointer" v-bind:class="{'non-selected':(selected == 'tarjetas' || selected == 'paypal')}" @click="selected = 'oxxo'">
                        </v-img>
                      </v-col> -->
                    </v-row>
                  </div>

                  <template v-if="!pagado">
                  
                    <template v-if="selected == 'tarjetas'">
                      <v-form id="forma" ref="forma" v-model="validPayment">
                        <div class="mt-12 mx-auto" style="max-width: 600px">
                          <v-row>
                            <v-col>
                              <p>
                                Has seleccionado Tarjeta de Crédito o Débito como tu
                                método de pago
                              </p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-0">
                              <v-text-field
                                :rules="requerido"
                                label="Nombre de tarjetahabiente"
                                data-conekta="card[name]"
                                required
                                outlined
                                color="#A06A4C"
                                :disabled="disablePaymentForm"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-0">
                              <v-text-field
                                :rules="requerido"
                                label="Número de la tarjeta"
                                data-conekta="card[number]"
                                required
                                outlined
                                type="number"
                                color="#A06A4C"
                                :disabled="disablePaymentForm"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-0">
                              <v-text-field
                                :rules="requerido"
                                label="Mes"
                                data-conekta="card[exp_month]"
                                placeholder="MM"
                                required
                                outlined
                                type="number"
                                color="#A06A4C"
                                :disabled="disablePaymentForm"
                              ></v-text-field>
                            </v-col>
                            <v-col class="py-0">
                              <v-text-field
                                :rules="requerido"
                                label="Año"
                                data-conekta="card[exp_year]"
                                placeholder="YYYY"
                                required
                                outlined
                                type="number"
                                color="#A06A4C"
                                :disabled="disablePaymentForm"
                              ></v-text-field>
                            </v-col>
                            <v-col class="py-0">
                              <v-text-field
                                :rules="requerido"
                                label="CVV"
                                data-conekta="card[cvc]"
                                required
                                outlined
                                type="number"
                                color="#A06A4C"
                                :disabled="disablePaymentForm"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              <v-btn
                                color="#ADC332"
                                class="mr-4"
                                height="45"
                                width="600"
                                max-width="100%"
                                @click="validarTarjetaConekta()"
                                :disabled="disablePaymentForm"
                              >
                                Pagar ahora
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-row v-if="disablePaymentForm">
                            <v-col>
                              Validando datos de la tarjeta
                              <v-progress-linear
                                color="#ADC332"
                                indeterminate
                                rounded
                                height="6"
                              ></v-progress-linear>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </template>

                    <template v-if="selected == 'paypal'">
                      <div class="mx-auto" style="max-width: 600px">
                        <v-row>
                          <v-col>
                            <p>Has seleccionado PayPal como tu método de pago</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <PayPal
                              @payment-completed="payment_completed_cb"
                              :amount="precioFinal"
                              currency="MXN"
                              :client="paypal"
                              :items="paypalItems"
                            >
                            </PayPal>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <p>
                              Una vez que presione
                              <span class="bold">"Pagar"</span> aparecerá la web de
                              PayPal para completar el pago.
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </template>
                  </template>

                  <!-- <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>

                  <v-btn text> Cancel </v-btn> -->
                </v-stepper-content>

                <v-stepper-content step="3">
                  <div v-if="pagado" class="mx-auto" style="max-width: 600px;">
                    <v-row>
                      <v-col>
                        <h1 class="text-center">¡Gracias por tu compra!</h1>

                        <p>
                          En breve recibirás un correo con todos los detalles de la compra a <span class="bold">{{pedido.email}}</span>, para dar seguimiento a tu pedido.
                          
                          Cuando el pedido salga del almacén, te notificaremos con un correo.
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        Id de pedido: {{pedido._id}}
                        <hr />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="bold">Nombre:</span>
                      </v-col>
                      <v-col>
                        {{pedido.nombre}} {{pedido.apellido}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="bold">Correo:</span>
                      </v-col>
                      <v-col>
                        {{pedido.email}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="bold">Teléfono:</span>
                      </v-col>
                      <v-col>
                        {{pedido.telefono}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="bold">Dirección:</span>
                      </v-col>
                      <v-col>
                        {{pedido.calle}} {{pedido.numero}} {{pedido.colonia}} {{pedido.codigoPostal}} {{pedido.ciudad}} {{pedido.estado}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="px-0">
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td class="text-left">
                                  <span class="bold">Producto</span>
                                </td>
                                <td class="text-left">
                                  <span class="bold">Cantidad</span>
                                </td>
                                <td class="text-left">
                                  <span class="bold">Precio</span>
                                </td>
                              </tr>
                              <tr v-for="(item, i) in pedido.items" :key="'item-'+i">
                                <td>
                                  {{ item.value.nombreProducto }}
                                </td>
                                <td>{{ item.value.cantidad }}</td>
                                <td>${{ numberWithCommas(item.value.precio) }}</td>
                              </tr>
                              <tr>
                                <td class="text-right" colspan="2">
                                  <span class="bold">Subtotal</span>
                                </td>
                                <td>
                                  <span>${{ numberWithCommas(pedido.subtotal) }}</span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-right" colspan="2">
                                  <span class="bold">Envío</span>
                                </td>
                                <td>
                                  <span>${{ numberWithCommas(pedido.envio) }}</span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-right" colspan="2">
                                  <span class="bold">Total</span>
                                </td>
                                <td>
                                  <span>${{ numberWithCommas(pedido.total) }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="text-center">
                        <v-btn
                          color="#A06A4C"
                          class="mx-auto"
                          height="45"
                          width="600"
                          max-width="100%"
                          to="/tienda"
                        >
                          Ir a la tienda
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="my-1">
              ¿Necesitas ayuda? Explora nuestras Páginas de ayuda o contacta con
              nosotros.
            </p>
            <p class="my-1">
              Al hacer clic en "Finalizar pedido", te enviaremos un e-mail
              notificándote que hemos recibido tu pedido. El contrato de compra no
              estará formalizado hasta que te enviemos un mensaje por e-mail
              notificándote que se ha enviado el producto.
            </p>
            <p class="my-1">
              Puedes devolver tus artículos dentro de en un plazo de 30 días a
              partir de la fecha de entrega, sin abrir y en su estado original. Se
              aplican restricciones y excepciones. Ver la Política de devoluciones
              de ejemplo.com.mx
            </p>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container>
        <v-row style="height: calc(100vh - 100px)" align="center">
          <v-col class="text-center">
            <h1>No hay items en el carrito.</h1>
            <v-btn
              color="#A06A4C"
              class="mx-auto"
              height="45"
              width="600"
              max-width="100%"
              to="/tienda"
            >
              Ir a la tienda
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import PayPal from "vue-paypal-checkout";
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);

export default {
  props: {
    cart: Array,
    total: Number,
    envio: Number,
    precioFinal: Number,
    ciudad: String,
    estado: Object,
  },
  data() {
    return {
      e1: 1,
      valid: false,
      validPayment: false,
      disablePaymentForm: false,
      nombre: '',
      apellido: '',
      email: "",
      telefono: '',
      calle: '',
      numero: '',
      colonia: '',
      codigoPostal: '',
      // estado: '',
      // ciudad: '',
      // nombre: "Eugenio",
      // apellido: "Cervantes",
      // email: "eugenio@we-act.mx",
      // telefono: "8115312182",
      // calle: "Calle uno",
      // numero: "501",
      // colonia: "Lopez Mateos",
      // codigoPostal: "66360",
      ciudadEnvio: "",
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      requerido: [(v) => !!v || "* Requerido"],
      emailRules: [
        (v) => !!v || "* Requerido",
        (v) => /.+@.+\..+/.test(v) || "* Correo inválido",
      ],
      select: null,
      selected: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
      factura: false,
      paypal: {
        sandbox:
          "AQrZozyhSXRATwmIgjDWmW1epNxrz0XL0uXp4wmWlmbftJ-lYAjDu-0Ui3JBhnhPyrCzJASNR_C-qRRr",
        production:
          "Abd_PLZYfRT_BiC9Ccu8LSAoJd5yjJKHcCFNDa88GTJJr-t0zngYyqtwlgBraWTEXp2AKycV14-tlOvC",
      },
      pagado: false,
      pedido: {
        _id: '',
        email: ''
      },
      parametros_conekta: {
        line_items: [],
        currency: "MXN",
        customer_info: {
          name: 'Eugenio',
          email: 'eugenio@we-act.mx',
          phone: '8115312182',
          payment_sources: [
            {
              type: 'card',
              token_id: 'tok_test_visa_4242'
            }
          ]
        },
        metadata: {
          reference: "",
          more_info: ""
        },

        charges: [{}]
      },
    };
  },
  computed: {
    datosPedidoConekta: function () {

      this.parametros_conekta.customer_info.name = this.nombre + ' ' + this.apellido;
      this.parametros_conekta.customer_info.email = this.email;
      this.parametros_conekta.customer_info.phone = this.telefono;
      for (var n in this.cart) {
        var elemento_conekta = {
          name: this.cart[n].presentacion.producto.nombre + ' ' + this.cart[n].presentacion.producto.clase.name + ' ' + this.cart[n].presentacion.contenido,
          unit_price: this.cart[n].presentacion.precio * 100,
          quantity: this.cart[n].cantidad
        };

        this.parametros_conekta.line_items.push(elemento_conekta);
      }

      var elemento_conekta = {
          name: 'Envío',
          unit_price: this.envio * 100,
          quantity: 1
        };

        this.parametros_conekta.line_items.push(elemento_conekta);

      return this.parametros_conekta;
    },
    datosPedidoTarjeta: function () {
      let datos_pedido = {
        nombre: this.nombre,
        apellido: this.apellido,
        email: this.email,
        telefono: this.telefono,
        calle: this.calle,
        numero: this.numero,
        colonia: this.colonia,
        codigoPostal: this.codigoPostal,
        ciudad: this.ciudadEnvio,
        estado: this.estadoEnvio,
        factura: this.factura,
        razonSocial: this.razonSocial,
        rfc: this.rfc,
        calleFactura: this.calleFactura,
        numeroFactura: this.numeroFactura,
        coloniaFactura: this.coloniaFactura,
        codigoPostalFactura: this.codigoPostalFactura,
        ciudadFactura: this.ciudadFactura,
        estadoFactura: this.estadoFactura,
        items: [],
        subtotal: this.total,
        envio: this.envio,
        total: this.precioFinal,
        pagadoCon: 'Tarjeta de débito o crédito'
      };

      let items = [];

      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];

        var producto_cockpit = {
          field: {
            type: "set",
            label: "Objetos",
            options: {
              fields: [
                {
                  name: "nombreProducto",
                  type: "text",
                },
                {
                  name: "contenido",
                  type: "text",
                },
                {
                  name: "clase",
                  type: "text",
                },
                {
                  name: "precio",
                  type: "text",
                },
                {
                  name: "cantidad",
                  type: "text",
                },
              ],
            },
          },
          value: {
            nombreProducto:
              element.presentacion.producto.categoria +
              " " +
              element.presentacion.producto.nombre +
              " " +
              element.presentacion.producto.clase.name +
              " " +
              element.presentacion.contenido,
            contenido: element.presentacion.contenido,
            clase: element.presentacion.producto.clase.name,
            precio: element.presentacion.precio,
            cantidad: element.cantidad,
          },
        };

        items.push(producto_cockpit);
      }

      datos_pedido.items = items;

      return datos_pedido
    },
    datosPedidoPaypal: function () {
      let datos_pedido = {
        nombre: this.nombre,
        apellido: this.apellido,
        email: this.email,
        telefono: this.telefono,
        calle: this.calle,
        numero: this.numero,
        colonia: this.colonia,
        codigoPostal: this.codigoPostal,
        ciudad: this.ciudadEnvio,
        estado: this.estadoEnvio,
        factura: this.factura,
        razonSocial: this.razonSocial,
        rfc: this.rfc,
        calleFactura: this.calleFactura,
        numeroFactura: this.numeroFactura,
        coloniaFactura: this.coloniaFactura,
        codigoPostalFactura: this.codigoPostalFactura,
        ciudadFactura: this.ciudadFactura,
        estadoFactura: this.estadoFactura,
        items: [],
        subtotal: this.total,
        envio: this.envio,
        total: this.precioFinal,
        pagadoCon: 'Paypal'
      };

      let items = [];

      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];

        var producto_cockpit = {
          field: {
            type: "set",
            label: "Objetos",
            options: {
              fields: [
                {
                  name: "nombreProducto",
                  type: "text",
                },
                {
                  name: "contenido",
                  type: "text",
                },
                {
                  name: "clase",
                  type: "text",
                },
                {
                  name: "precio",
                  type: "text",
                },
                {
                  name: "cantidad",
                  type: "text",
                },
              ],
            },
          },
          value: {
            nombreProducto:
              element.presentacion.producto.categoria +
              " " +
              element.presentacion.producto.nombre +
              " " +
              element.presentacion.producto.clase.name +
              " " +
              element.presentacion.contenido,
            contenido: element.presentacion.contenido,
            clase: element.presentacion.producto.clase.name,
            precio: element.presentacion.precio,
            cantidad: element.cantidad,
          },
        };

        items.push(producto_cockpit);
      }

      datos_pedido.items = items;

      return datos_pedido
    },
    paypalItems: function () {
      let ppcart = [];
      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];
        ppcart.push({
          name:
            element.presentacion.producto.categoria +
            " " +
            element.presentacion.producto.nombre,
          description: element.presentacion.contenido,
          quantity: element.cantidad,
          price: parseInt(element.presentacion.precio),
          currency: "MXN",
        });
      }
      ppcart.push({
        name: "Envio",
        quantity: 1,
        price: this.envio,
        currency: "MXN",
      });
      return ppcart;
    },
    estadoEnvio: function () {
      return this.estado.name;
    },
    ciudadSeleccionada: function () {
      if (this.ciudad != false) {
        return true;
      }
      return false;
    },
  },
  mounted: function () {
    if(this.ciudadSeleccionada){
      this.ciudadEnvio = this.ciudad
    }
  },
  watch: {
    ciudad: function () {
      this.ciudadEnvio = this.ciudad;
    },
    e1: function () {
      this.$vuetify.goTo('#stepper');
    }
  },
  components: {
    PayPal,
  },
  methods: {
    validarTarjetaConekta: function () {

      if(this.$refs.forma.validate()){

        this.disablePaymentForm = true;

        Conekta.setPublicKey("key_TVGLrXedy8siuomxgk1ByXQ"); //Producción
        //Conekta.setPublicKey("key_BnLQcDpnyqynYL5ZEarkmbQ"); // Pruebas
  
        // this.parametros_conekta.customer_info.email = this.datos_envio.email;
        // this.parametros_conekta.customer_info.name = this.datos_envio.name;
        // this.parametros_conekta.customer_info.phone = this.datos_envio.phone;
        var $form = $("#forma");
        // Previene hacer submit más de una vez
        Conekta.Token.create(
          $form,
          this.conektaSuccessResponseHandler,
          this.conektaErrorResponseHandler
        );
      }
    },
    conektaSuccessResponseHandler: function (token) {
      this.token_tarjeta = token.id;
      this.parametros_conekta.charges[0] = {
        payment_method: {
          type: "card",
          token_id: token.id
        },
        livemode: true,
        amount: this.precioFinal * 100
      };
      this.parametros_conekta.charges[0].payment_method.token_id = token.id;
      this.parametros_conekta.customer_info.payment_sources[0].token_id = token.id;

      this.procesarConekta();
    },
    conektaErrorResponseHandler: function (token) {
      alert('Error al procesar tu tarjeta');
      this.disablePaymentForm = false;
    },
    procesarConekta: function () {
      const aux = this;
      axios({
        method: "POST",
        baseURL: "https://www.utilidades.elmurcielagotequileria.actstudio.xyz/conekta.php",
        data: this.datosPedidoConekta
      }).then(function(res){
        if(res.data.payment_status == 'paid'){

          console.log(res);
          aux.guardarPedido();
        }else{
          alert('Error al procesar la tarjeta');
          this.disablePaymentForm = false;
        }
      });
    },
    guardarPedido: function () {
      fetch('https://content.elmurcielagotequileria.com/api/collections/save/Pedidos', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              data: this.datosPedidoTarjeta
          })
      })
      .then(res=>res.json())
      .then(entry => {
        this.disablePaymentForm = false;
        this.pedido = entry;
        this.pagado = true;
        this.e1 = 3;
        this.disminuirInventario();
        this.enviarCorreo();
      });
    },
    enviarCorreo: function () {
      fetch('https://www.utilidades.elmurcielagotequileria.actstudio.xyz/correo_venta.php?referencia='+this.pedido._id);
    },
    disminuirInventario: function () {

      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];

        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Presentaciones', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: { _id: element.presentacion._id}
            })
        })
        .then(res=>res.json())
        .then(res => {
          let presentacion = res.entries[0];
          presentacion.unidadesDisponibles = parseInt(presentacion.unidadesDisponibles) - element.cantidad

          fetch('https://content.elmurcielagotequileria.com/api/collections/save/Presentaciones', {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  data: presentacion
              })
          })
          .then(res=>res.json())
          .then(entry => console.log(entry));
        });
      }

      this.enviarCorreo();
      this.$session.set('emcart',[])
      this.$emit('update');
    },
    validarDatosEnvio() {
      if (this.$refs.form.validate()) {
        this.e1 = 2;
      }
    },
    numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    payment_completed_cb(res, planName) {
      console.log(res);
      console.log(planName);

      

      fetch('https://content.elmurcielagotequileria.com/api/collections/save/Pedidos', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              data: this.datosPedidoPaypal
          })
      })
      .then(res=>res.json())
      .then(entry => {
        this.pedido = entry;
        this.pagado = true;
        this.e1 = 3;
        this.disminuirInventario();
        this.enviarCorreo();
      });


    },
  },
  beforeMount: function () {
  }
};
</script>